<template>
  <div class="message-containter">
    <nav-menu></nav-menu>
    <div class="message-body">
      <div class="containter flex">
        <div class="message-left">
          <div>消息中心</div>
        </div>
        <div class="message-right">
          <div class="message-list">
            <div class="message-total flex-a">
              <span class="line"></span>
              <span>共256条记录</span>
            </div>
            <div class="message-item" v-for="item in 10" :key="item.id">
              <div class="message-item-title">中国成功试验陆基中段反导拦截技术</div>
              <div class="message-item-content flex-a flex-b">
                <div>据国防部网站消息，2022年6月19日晚，中国在境内进行了一次陆基中段反导拦截技术试验，试验达到了预期目的，这一试验是...</div>
                <div class="message-item-time">2022-06-20</div>
              </div>
            </div>
          </div>
          <div class="page-list">
            <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange" :total="20">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
export default {
  name: 'message',
  data() {
    return {

    }
  },
  created() {
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    //当前页
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
  },
  computed: {

  }
}
</script>

<style lang="less">
.message-containter {
  background: #F0F2F5;
  min-height: 100vh;

  .message-body {
    padding: 20px 0;

    .containter {
      padding: 0;

      .message-left {
        background: #F5F6F8;
        width: 200px;
        height: 500px;
        border-radius: 5px;

        div {
          background: #5478C5;
          text-align: center;
          height: 60px;
          line-height: 60px;
          color: #fff;
        }
      }

      .message-right {
        padding: 20px;
        width: 100%;

        .message-list {
          border: 1px solid #E1E7EF;
          border-top: none;

          .message-total {
            background: #F5F6F8;
            height: 40px;
            color: #666;
            font-size: 14px;

            .line {
              display: inline-block;
              width: 3px;
              height: 40px;
              background: #3577E7;
              margin-right: 5px;
            }
          }

          .message-item {
            height: 70px;
            padding: 15px 15px;
            box-sizing: border-box;

            .message-item-title {
              font-size: 14px;
              color: #333;
              margin-bottom: 5px;
            }

            .message-item-content {
              color: #666;
              font-size: 12px;

              div:first-child {
                width: 85%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
              }

              .message-item-time {
                color: #999;
              }
            }
          }

          .message-item:not(:last-child) {
            border-bottom: 1px solid #F3F6F9;
          }

          .message-item:hover {
            cursor: pointer;
          }
        }
        .page-list {
          margin: 20px auto;
          text-align: center;
        }
      }
    }
  }
}
</style>
